import { useContext, useEffect, useState } from "react";
//import YouTube from "react-youtube";
import ReactPlayer from 'react-player/youtube';
import { ContextReload } from "../App";

export default function VideoContainer(props) {

    const [playerPosition, setPlayerPosition] = useState(0)
    const media = props.media;

    var context = useContext(ContextReload)
    useEffect(() => {    
        if(context.reload){
            setPlayerPosition(0)
        }
    },[context])

    return (
        <div className="media_container visible">
            {

                media.length > 0 && (
                    <><p style={{ color: "#fff", zIndex: "99999" }}></p>
                        <div className="media_container_media active" >
                            {

                                (media[playerPosition].url).startsWith('http')
                                    ? <video className="video"
                                        src={media[playerPosition].url}
                                        playsInline={true}
                                        muted={true}
                                        onLoadedMetadata={(e) => {
                                            e.target.play().catch( e => {alert(e); videoReload(e.target) });
                                        }}
                                        onError={ e =>{
                                            videoReload(e.target)
                                        }}
                                        /*onCanPlayThrough={(e) => {
                                            e.target.play();
                                        }}*/
                                        onEnded={(e) => {
                                            //e.target.classList.remove("active");
                                            media.length === 1 ? setPlayerPosition(0) : (playerPosition + 1 < media.length) ? setPlayerPosition(playerPosition + 1) : setPlayerPosition(0)

                                        }}></video>
                                    : <ReactPlayer playing={true}
                                        className="video"
                                        url={`https://www.youtube.com/watch?v=${media[playerPosition].url}&origin=https://trm.joyeriainter.com`}
                                        width="100%"
                                        height="100%"
                                        playsinline={true}
                                        loop={media.length === 1 && true}
                                        muted={true}
                                        onPlay={ (e) => {
                                        }}
                                        onEnded={(e) => {
                                            //(playerPosition + 1 < media.length) ? setPlayerPosition(playerPosition + 1) : setPlayerPosition(0)
                                            media.length === 1 ? setPlayerPosition(0) : (playerPosition + 1 < media.length) ? setPlayerPosition(playerPosition + 1) : setPlayerPosition(0)
                                        }}
                                        config={{
                                            youtube: {
                                                playerVars: {
                                                    enablejsapi: 1,
                                                    disablekb: 1,
                                                    modestbranding: 1,
                                                    rel: 0,
                                                    showinfo: 0,
                                                    playsinline: 1,
                                                    mute: 1,
                                                    controls: 1,
                                                    origin: "https://trm.joyeriainter.com",
                                                },
                                            },
                                        }}
                                        />
                                    /*<YouTube className="video"
                                        videoId={media[playerPosition].url}
                                        opts={opts}
                                        onReady={e => e.target.playVideo() }
                                        onEnd={(e) => {
                                            media.length === 1 ? e.target.playVideo() : (playerPosition + 1 < media.length) ? setPlayerPosition(playerPosition + 1) : setPlayerPosition(0)
                                        }} />*/

                            }
                        </div>
                    </>
                )

            }</div>
    )

    function videoReload(video){
        setTimeout( () => {
            video.load()
            setPlayerPosition(playerPosition)
        }, 5000)
    }


}