import { createContext, useEffect, useState } from 'react';
import './App.css';
import Trm from './components/Trm';
import VideoSlider from './components/VideoSlider';
import Vistas from './components/Vistas';

export const ContextReload = createContext(false);

export default function App() {

	const [reload, setReload] = useState(false)
	function restartReload(){ setReload(false)}

	useEffect(() => {

		var intervalo = setInterval((e) => new Date(new Date().toLocaleString('en-US', {timeZone: 'America/Bogota'})).getHours() === 8 && refresh(intervalo) , 1000 * 60 * 60 * 1); /** detectar cada "1" hora */
		//setInterval((e) => time.getUTCHours() === 13 && window.location.reload(false) , 1000 * 60 * 60 * hour);

		function refresh(e){
			clearInterval(e)
			!reload && setReload(true)
		}

		//hourDetection(time, 1) /** detectar cada "1" hora */

	})
	
	var params = new URLSearchParams(window.location.search);
	const urlSheets = "https://docs.google.com/spreadsheets/d/1_wzdqtGZ6vol1U5UL9zqGHcwXbKVHBzj4MthX8b9mFw/gviz/tq?tqx=out:csv";

	if (params.has('admin')) {

		return (
			<div className="main_container home">
				<Vistas id={urlSheets} />
				<Trm />
			</div>
		)

	} else if (params.toString() && params.get('trm') !== 'real' && params.get('trm') !== 'cambio') {

		return (
			<ContextReload.Provider value={{reloadChange: restartReload,reload}}>
				<div className="main_container">
					<VideoSlider id={urlSheets} />
					<Trm />
				</div>
			</ContextReload.Provider>

		)

	} else {
		return (
			<ContextReload.Provider value={{reloadChange: restartReload,reload}}>
				<div className="main_container home">
					<Trm />
				</div>
			</ContextReload.Provider>
		);
	}

}