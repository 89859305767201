import { useState } from "react";

export default function Vistas(props){
    
	const [vistaView, setVistaView] = useState(false);
    const [csv, setCsv] = useState(false);

    const getCsv = (url) => {
        fetch(url, {
            cache: 'no-cache',
            headers: {
                'Content-Type': 'text/csv'
            }
        })
            .then(response => response.text())
            .then(data => setCsv(data.replaceAll('"','')))
    }

    if(!csv){
        getCsv(props.id);
    }else{

        const csvHeaders = csv.split("\n")[0].toLowerCase().split(",");
        const vistas = csvHeaders.map( (e) => (
            e.startsWith("vista") && e.replace("vista:","").trim()
        ) )

        return(
        
            <div className={(vistaView) ? "vista_container active" : "vista_container"}>
                <div className="vista_container_vistas">
                    {
                        vistas.map( (vista, i) => (

                            (vista) && (

                                <div key={i} className="vista_container_element">
                                    <div className="vista_container_element_vista">
                                        <span>{vista}</span>
                                    </div>
                                    <div className="vista_container_element_info">
                                        <a href={window.location.origin + `/?vista=${encodeURIComponent(vista)}`}>Con <span>TRM</span></a>
                                        <a href={window.location.origin + `/?vista=${encodeURIComponent(vista)}&trm=0`}>Sin <span>TRM</span></a>
                                    </div>
                                </div>

                            )
                        ) )
                    }
                </div>
                <div className={(vistaView) ? "close active" : "close"} onClick={(e) => setVistaView(!vistaView) }>Pantallas</div>
            </div>
    
        )
    }


}