import { useContext, useEffect, useState } from "react"
import { ContextReload } from "../App"
//import VideoAll from "./VideoAll"
import VideoContainer from "./VideoContainer"

export default function VideoSlider(props) {

    const [csv, setCsv] = useState(null)
    
    var context = useContext(ContextReload)
    useEffect(() => {    
        if(context.reload){
            setCsv(null)
        }
    },[context])

    function getCsv(url) {
        fetch(url, {
            cache: 'no-cache',
            headers: {
                'Content-Type': 'text/csv'
            }
        })
            .then(response => response.text())
            .then(data => setCsv(data.replaceAll('"','')))
    }

    if (!csv) {

        getCsv(props.id)
        return <VideoContainer media={false} />

    } else {
        const csvHeaders = csv.split("\n")[0].split(",");
        const csvContent = csv.split("\n").map((element) => (element = element.split(",")));
        csvContent.shift();
        csvHeaders.forEach((e, i) => {
            csvHeaders[i] = e.toLowerCase().trim();
        });

        const media = getMedia(csvHeaders, csvContent);
        //return <VideoAll media={media}/>
        return <VideoContainer media={media} />

    }
    

}

function getMedia(headers, contents) {
    
    const params = new URLSearchParams(window.location.search);
    const vista = params.get("vista");
    const iHeaders = {
        activo: headers.indexOf('activo'),
        media: headers.indexOf('url'),
        vista: headers.indexOf('vista:' + vista) < 0 ? headers.indexOf('vista: ' + vista) : headers.indexOf('vista:' + vista)
    }
    const media = []
    contents.forEach((e) => {
        
        const sendMedia = bool => (bool) && media.push({ url: e[iHeaders.media] })

        if(vista){
            if( e[iHeaders.activo] === "1" && vista && e[iHeaders.vista] === "1"){
                sendMedia(true);
            }else { sendMedia(false); }
        }else{ sendMedia(false); }		

    })
    return media.length > 0 && media

}